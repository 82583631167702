import React from "react"
import { Link } from 'gatsby'
import styled from "styled-components"

const TextContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 100px;

  @media(max-width: 894px) {
		padding: 0 30px;
  }
`

const Text = styled.p`
	font-family: ${({ theme }) => theme.font.family.circular};
	color: white;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0px;
`

const TermsLink = styled(Link)`
  color: white;

  &:hover {
    color: ${({ theme }) => theme.colors.fonts.lightGreen};
  }
`

const TermsTextSection = () => (
	<section>
    <TextContainer>
      <Text>SUMMARY OF YOUR KEY RIGHTS:</Text>
      <br />
      <Text>
        The Consumer Contracts (Information, Cancellation and Additional Charges) Regulations 2013 say that you have a 14 day right to change your mind and get a full refund on your digital content. You do not have this right to cancel once a download has started provided you have been told this and have acknowledged this.
      </Text>
      <Text>
        The Consumer Rights Act 2015 says digital content must be as described, fit for purpose and of satisfactory quality.
      </Text>
      <Text>
        —if your digital content is faulty, you’re entitled to a repair or a replacement;
      </Text>
      <Text>
        —if the fault can’t be fixed, or if it hasn’t been fixed within a reasonable time and without significant inconvenience, you can get some, or all of your money back;
      </Text>
      <Text>
        —if you can show the fault has damaged your device and we haven’t used reasonable care and skill, you may be entitled to a repair or compensation.
      </Text>
      <Text>
        This is a summary of some of your key rights. For detailed information from Citizens Advice,
        please visit <TermsLink to="https://www.citizensadvice.org.uk/">www.citizensadvice.org.uk</TermsLink> or call 03454 04 05 06.
      </Text>
      <Text>
        The information in this summary box summarises some of your key rights. It is not intended to replace the Terms below which you should read carefully.
      </Text>
      <Text>
        1. INTRODUCTION
      </Text>
      <Text>
        1.1 These Terms set out:
      </Text>
      <Text>
        1.1.1. your legal rights and responsibilities;
      </Text>
      <Text>
        1.1.2. our legal rights and responsibilities; and
      </Text>
      <Text>
        1.1.3. certain key information required by law.
      </Text>
      <Text>
        1.2. These Terms apply to you as our customer and constitute a legally binding agreement which governs the relationship between you and us.
      </Text>
      <Text>
        1.3. If you don’t understand any of the Terms and want to talk to us about it, please contact us by:
      </Text>
      <Text>
        1.3.1. email at hello@myarkeo.com; or
      </Text>
      <Text>
        1.3.2. telephone on 07841575981 calls will be answered Monday to Friday: 9:30am to 4pm.
      </Text>

      <Text>
        2. INFORMATION ABOUT US
      </Text>
      <Text>
        2.1. In these Terms we, us or our means My Arkeo Ltd, a company registered in England and Wales under company number 11591732 and registered office address at 4a Petherton Road, London, United Kingdom, N5 2RD. 
      </Text>
      <Text>
        2.2. References to you or your means the person downloading or using the Arkeo mobile application (the App).
      </Text>

      <Text>
        3. YOUR PRIVACY
      </Text>
      <Text>
        3.1 Your privacy and personal information are important to us. Any personal information that you provide to us will be dealt with in line with our Privacy Policy (available at www.myarkeo.com/privacypolicy, which explains what personal information we collect from you, how and why we collect, store, use and share such information, your rights in relation to your personal information and how to contact us and supervisory authorities if you have a query or complaint about the use of your personal information.
      </Text>

      <Text>
        4. SCOPE AND NATURE OF OUR SERVICE 
      </Text>
      <Text>
        4.1. Our App measures mental fitness by tracking your patterns and highlighting common trends of behaviour (our Service). We may, in our sole discretion at any time, update, change, suspend, improve or discontinue any aspect of the Service temporarily or permanently provided that such changes do not materially decrease the functionality of the Service.
      </Text>
      <Text>
        4.2. When you choose to download our App, you will be presented with these Terms and if you choose not to agree to these Terms, you will not be able to download the App or access our Service. If you agree to these Terms, you can download the App via a third-party app store and access certain features, depending on the subscription that you choose. 
      </Text>
      <Text>
        4.3. These Terms commence on the date on which you accept them and shall continue until cancelled in accordance with Clause 7.
      </Text>
      <Text>
        4.4. Our Service is provided on an “as-is” and “as available” basis and without warranties of any kind either express or implied. You assume all responsibility for accessing and using the App and for the selection of the Services to achieve your intended results. We disclaim all warranties, express or implied, including without limitation, implied warranties of merchantability, title, fitness for a particular purpose and non-infringement in respect of the Services.
      </Text>

      <Text>
        5. MEDICAL DISCLAIMER
      </Text>
      <Text>
        5.1. We are not a medical provider and our Service should not be considered medical advice. We are not a treatment or diagnosis tool and you should always consult a medical professional if you have concerns about your mental fitness or general wellbeing.
      </Text>
      <Text>
        5.2. Any health information and/or links provided through or in connection with our App, whether by us or from a third-party, are provided for convenience only and should not be considered medical advice or endorsement. Such information is not intended to be relied upon and is not a substitute for professional medical advice.
      </Text>
      <Text>
        5.3. You are responsible for your own use of the App and our Service. We are not responsible for your mental fitness or any particular progress or outcome. We make no guarantees in respect of your use our Service. In particular, to the fullest extent permitted by law, we make no representation or warranties about the accuracy, completeness, or suitability for any purpose of the advice, materials and information published as part of or in connection with our Service or App.
      </Text>

      <Text>
        6. CREATING YOUR ACCOUNT AND CHOOSING YOUR SUBSCRIPTION
      </Text>
      <Text>
        6.1. In order to access our Service, you must create an account through the App. You will be required to provide certain information including your name, email address and questions about your general day-to-day routine (such as your work, sleep, eating and exercise patterns).
      </Text>
      <Text>
        6.2. Once you have created an account, you will have access to our Basic App. You will not be required to pay for use of our Basic App. However, you will only have access to our basis content and features, as updated from time to time.
      </Text>
      <Text>
        6.3. For full access to our App features and functionality, including our reminder service, feedback of data and targets and goals (Advanced Features), you must pay the monthly subscription fee set out at <TermsLink to="https://www.myarkeo.com/fee">www.myarkeo.com/fee</TermsLink> and purchase a subscription in accordance with Clause 7.4 below.
      </Text>
      <Text>
        6.4. If you choose to subscribe to our Advanced Features, you will be redirected to the applicable third-party payment system (the Payment Provider) and prompted to enter your payment information. Your purchase will be subject to the relevant Payment Provider’s terms and conditions, as listed below:
      </Text>
      <Text>
        6.4.1. Apple Pay: <TermsLink to="https://www.apple.com/legal/privacy">www.apple.com/legal/privacy</TermsLink>
      </Text>
      <Text>
        6.4.2. Google Play: <TermsLink to="https://payments.google.com/payments/apissecure/u/0/get_legal_document?ldo=0&ldt=googlepaytos&ldl=en-GB">payments.google.com/payments</TermsLink>
      </Text>
      <Text>
        You should read their terms carefully before proceeding to payment as they may not provide refunds. By entering your payment details, you agree to pay all subscription fees incurred by you or anyone else using an account registered to you. 
      </Text>
      <Text>
        6.5. For the period that your subscription is active, you acknowledge and agree that the third-party Payment Provider is authorised to charge the same credit or debit card details entered at the initial purchase. You will continue to be charged the subscription fee unless and until cancelled in accordance with Clause 8. You must cancel your subscription before it renews in order to avoid payment of the next month’s subscription fee.
      </Text>
      
      <Text>
        7. CANCELLATIONS
      </Text>
      <Text>
        7.1. If you wish to cancel your subscription, you can do so at any time by emailing us at hello@myarkeo.com or by changing your billing preferences in accordance with the Payment Provider’s terms and conditions. 
      </Text>
      <Text>
        7.2. Subscription fees are paid monthly in advance and after cancellation of your subscription, you will continue to have access to the Advanced Features until expiry of one calendar month after the latest payment date of the subscription fees (Cancellation Period). After expiry of the Cancellation Period, the functionality of the App will automatically reduce to the Basic App and you will lose access to certain features and/or information stored or available as part of the Advanced Features.
      </Text>
      <Text>
        7.3. You acknowledge and agree that access to the Advanced Features through the App is provided immediately upon receipt of payment of the subscription fees and you are not entitled to a refund of any fees paid should you decide to terminate these Terms thereafter.
      </Text>
      <Text>
        7.4. We may immediately suspend or cancel your use of the App or Service at any time without notice if we suspect that you have breached these Terms or there has been any fraudulent activity related to your account. A breach of these Terms includes, but is not limited to, unauthorised copying or downloading content from our App.
      </Text>

      <Text>
        8. PERMISSION TO USE THE APP
      </Text>
      <Text>
        8.1. When you download and access our App, you will not own any of the content and instead, we give you permission to use the App and our Service (known as a Licence) for the purpose of using and enjoying our App in accordance with these Terms.
      </Text>
      <Text>
        8.2. You Licence is personal to you but it is not exclusive meaning that we may supply the same or similar digital content and Services to other customers. 
      </Text>
      <Text>
        8.3. You may not:
      </Text>
      <Text>
        8.3.1. copy any of the information provided via the App;
      </Text>
      <Text>
        8.3.2. change the App or Service (which means, in particular, that you are not permitted to adapt, reverse-engineer or decompile the App or Service, or try to extract the source code from it); 
      </Text>
      <Text>
        8.3.3. combine, merge with, or use the App in any other computer program or application;
      </Text>
      <Text>
        8.3.4. distribute or sell the App or Service to any third party.
      </Text>
      <Text>
        8.4. Except where you have permission to use the App under this Clause 8, you will not obtain any rights of ownership or other rights (of whatever nature) in the App and/or Service or in any copies of it or any digital content provided.
      </Text>
      
      <Text>
        9. NATURE OF THE DIGITAL CONTENT
      </Text>
      <Text>
        9.1. The Consumer Rights Act 2015 gives you certain legal rights (also known as statutory rights), for example, that the digital content we provide through our App:
      </Text>
      <Text>
        9.1.1. is of satisfactory quality;
      </Text>
      <Text>
        9.1.2. is fit for purpose; and
      </Text>
      <Text>
        9.1.3. matches its description.
      </Text>
      <Text>
        9.2. We must provide you with digital content that complies with your legal rights.
      </Text>
      <Text>
        9.3. When we supply the digital content:
      </Text>
      <Text>
        9.3.1. we will use all reasonable efforts to ensure that it is free from defects, viruses and other malicious content;
      </Text>
      <Text>
        9.3.2. we do not promise that it is compatible with any third-party software or equipment; and
      </Text>
      <Text>
        9.3.3. you acknowledge that there may be minor errors or bugs in it.
      </Text>

      <Text>
        10. LIMIT ON OUR RESPONSIBILITY TO YOU
      </Text>
      <Text>
        10.1. Except for any legal responsibility that we cannot exclude in law (such as for death or personal injury) or arising under applicable laws relating to the protection of your personal information, we are not legally responsible for:
      </Text>
      <Text>
        10.1.1. losses that were not foreseeable to you and us at the time you entered into these Terms; or
      </Text>
      <Text>
        10.1.2. losses that that were not caused by any breach on our part;
      </Text>
      <Text>
        10.1.3. business losses; and
      </Text>
      <Text>
        10.1.4. losses to non-consumers.
      </Text>
      <Text>
        10.2. Whilst we will make every effort to ensure the smooth running of our App, we cannot guarantee uninterrupted use of the App or our Service and we are not liable for any losses suffered by you arising out of or in connection with any interruptions, breakdown, repair, upgrade or maintenance to our App or Service.
      </Text>
      <Text>
        10.3. Our Service may contain links to third-party applications, services and/or websites that are not controlled by us. We are not liable for any third-party applications, services, information, materials or products. If you access any third-party sites, applications, services or products, you acknowledge and agree that we are in no way responsible, whether directly or indirectly, for any losses arising out of or in connection with your use of any third-party applications, services and/or websites.
      </Text>

      <Text>
        11. DISPUTES
      </Text>
      <Text>
        11.1. If you are unhappy with the content we provide through our App, our Service or any other matter, please contact us as soon as possible. We will try to resolve any disputes with you quickly and efficiently.
      </Text>
      <Text>
        11.2. These Terms and any dispute arising out of or in connection with these Terms will be governed by English law and subject to the exclusive jurisdiction of the English courts.
      </Text>
      
      <Text>
        12. GENERAL
      </Text>
      <Text>
        12.1. No one other than you and us, as the parties to these Terms, has any right to enforce any of the terms set out herein. 
      </Text>
      <Text>
        12.2. We may update and amend these Terms at any time by giving you one (1) week’s written notice. 
      </Text>
      <Text>
        12.3. If any provision or part-provision of these Terms is or becomes invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, the relevant provision or part-provision shall be considered deleted. Any modification to or deletion of a provision or part-provision under this Clause shall not affect the validity and enforceability of the rest of these Terms.
      </Text>
    </TextContainer>
  </section>
)

export default TermsTextSection
