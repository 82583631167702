import React from 'react'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import Terms from "components/Terms/Terms"

const TermsPage = () => (
  <MainTemplate title="Arkeo - Terms">
    <Terms />
  </MainTemplate>
)

export default TermsPage
