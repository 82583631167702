import React from "react"
import PrivacyTermsTemplate from 'components/PrivacyTermsTemplate'
import TermsSection from './TermsSection'

const Terms = () => (
	<PrivacyTermsTemplate
    showExtraSection
    title="ARKEO TERMS & CONDITIONS"
  >
    <TermsSection />
  </PrivacyTermsTemplate>
)

export default Terms
